import React from "react";
import "./styles/AboutMe.css";

function AboutMe(props) {
  return (
    <div className="about-me">
      <h2 className="about-me-heading">About me</h2>
      <p className="about-me-p">
        My name is alireza zabihi and i'm a front-end web developer. this is my
        personal website, here i will share my personal projects, indie games,
        tutorials and ...
      </p>
    </div>
  );
}

export default AboutMe;
